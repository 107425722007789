import styles from "./header.module.scss";
import logo from "../../images/OU.png";
import resume from "../../resume.pdf";
import { FiDownload } from "react-icons/fi";
import { useState } from "react";

export default function Header() {
  const [hidden, setHidden] = useState(true);

  const showNavBar = () => {
    const oneVhInPx = window.innerHeight / 100;
    const height = oneVhInPx * 60;
    if (window.scrollY >= height) {
      setHidden(false);
    } else {
      setHidden(true);
    }
  };

  window.addEventListener("scroll", showNavBar);

  return (
    <div
      className={
        hidden
          ? `${styles.headerContainer} ${styles.hidden}`
          : styles.headerContainer
      }
    >
      <img className={styles.logo} alt={"app logo"} src={logo} />
      <div className={styles.menu}>
        {/* <a href="#">About</a>
        <a href="#">Experience</a>
        <a href="#">Education</a>
        <a href="#">Certificates</a> */}
      </div>
      <a href={resume} download="onimisi-ukanah">
        <FiDownload />
        Download Resume
      </a>
    </div>
  );
}
