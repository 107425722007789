import styles from "./content.module.scss";
import cdaLogo from "../../images/cda-logo.png";
import saLogo from "../../images/sa-logo.png";

const Skills = {
  Languages: ["JavaScript", "TypeScript", "Swift", "Kotlin"],
  Frameworks: ["Angular/AngularJS", "React", "Ionic", "SCSS"],
  Databases: ["MongoDB", "Firestore", "MySQL", "PostgreSQL"],
  Tools: ["Git/Github", "Jira", "AWS", "Firebase"],
};

const Experiences = [
  {
    company: "Eaton Corporation",
    jobTitle: "Front-end Developer",
    startDate: "April 2022",
    endDate: "present",
    location: "Toronto, ON",
    description: [
      "Built new functionality and features for the company's software platform using Angular and SCSS.",
      "Improved business requirements templates to align stakeholder and developer goals, promoting efficiency.",
      "Created standardized documents to simplify the onboarding process for new developers.",
      "Refactored components of the application to promote clarity, reusability, and maintainability.",
    ],
  },
  {
    company: "Be One To Give",
    jobTitle: "Lead Software Developer",
    startDate: "November 2022",
    endDate: "present",
    location: "Toronto, ON",
    description: [
      "Architect and refine features using Swift, Kotlin, and Node.js, resulting in successful feature releases.",
      "Conduct daily code reviews, provide valuable feedback, and ensure clean and consistent code across the platform.",
      "Utilize pair programming techniques to explore and implement innovative solutions, resulting in more efficient and user-friendly applications.",
      "Headed up the initiative to plan and improve the scalability and efficiency of our infrastructure by containerizing the Node.js app",
    ],
  },
  {
    company: "TOVI Health",
    jobTitle: "Full-Stack Developer",
    startDate: "January 2021",
    endDate: "March 2022",
    location: "Toronto, ON",
    description: [
      "Played a crucial role in the end-to-end implementation of a cloud project that improved the internal team's efficiency and enhanced the app's user experience.",
      "Worked extensively with Ruby to develop and improve API functionality while regularly testing each update for quality assurance.",
      "Worked closely with a team member to identify and address the source of latency in a critical database query, resulting in a notable increase in system performance.",
    ],
  },
];

const Education = [
  {
    school: "University of Guelph",
    degree: "Bachelor of Computing",
    location: "Guelph, ON",
  },
  {
    school: "BrainStation",
    degree: "Full-Stack Web Development",
    location: "Toronto, ON",
  },
];

const Certificates = [
  {
    title: "AWS Certified Developer Associate",
    startDate: "03/2023",
    endDate: "02/2026",
    image: cdaLogo,
  },
  {
    title: "AWS Certified Developer Associate",
    startDate: "12/2022",
    endDate: "11/2025",
    image: saLogo,
  },
];

export default function Content() {
  return (
    <div className={styles.content}>
      <div
        className={`${styles.contentContainer} ${styles.contentContainer_center}`}
      >
        <h2>About</h2>
        <div>
          <p>
            Welcome, Welcome! I'm a passionate software engineer based in
            Canada, exploring to the world of cloud technologies and
            infrastructure development. With a deep-rooted curiosity and drive
            for innovation, I constantly explore the latest advancements in this
            rapidly evolving field.
          </p>
          <br />
          <p>
            When I'm not immersed in coding and building robust systems, you'll
            likely find me indulging in my other interests. I have a knack for
            finding the best deals on flights, whether it's redeeming points or
            finding paid options that suit my wanderlust. Exploring new
            destinations and cultures is a source of inspiration that fuels my
            creativity.
          </p>
          <br />
          <p>
            In my downtime, I love to unwind by watching mouth-watering cooking
            videos that ignite my culinary imagination. Exploring flavors and
            experimenting in the kitchen is my way of adding a dash of
            creativity to my everyday life. And of course, who doesn't enjoy a
            good binge-worthy show? Discovering new series and immersing myself
            in captivating narratives is my guilty pleasure.
          </p>
          <br />
          <p>
            If you find something intriguing, feel free to reach out. Enjoy your
            visit!
          </p>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <h2 className={styles.contentHeader}>Skills</h2>
        <div className={styles.skills}>
          {Object.keys(Skills).map((key, x) => (
            <div key={x}>
              <h3>{key}</h3>
              <ul>
                {Skills[key].map((value, e) => (
                  <li key={e}>{value}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.contentContainer}>
        <h2 className={styles.contentHeader}>Experience</h2>
        {Experiences.map((e, x) => (
          <div key={x}>
            <div className={styles.experienceHeader}>
              <div>
                <h3>{e.company}</h3>
                <p className={styles.jobTitle}>{e.jobTitle}</p>
                <p>{e.location}</p>
              </div>
              <p>
                {e.startDate} - {e.endDate}
              </p>
            </div>
            <div className={styles.description}>
              <ul>
                {e.description.map((description, x) => (
                  <li key={x}>{description}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.contentContainer}>
        <h2 className={styles.contentHeader}>Education</h2>
        {Education.map((e, x) => (
          <div key={x} className={styles.education}>
            <div className={styles.educationHeader}>
              <h3>{e.school}</h3>
            </div>
            <p>{e.degree}</p>
          </div>
        ))}
      </div>
      <div className={styles.contentContainer}>
        <h2 className={styles.contentHeader}>Certificates</h2>
        <div className={styles.certificates}>
          {Certificates.map((c, x) => (
            <div key={x}>
              <img alt="" src={c.image} />
              <h3>{c.title}</h3>
              <p>
                {c.startDate} - {c.endDate}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
