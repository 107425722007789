import React from "react";
import "./App.scss";
import Hero from "./components/Hero/hero.component";
import Header from "./components/Header/header.component";
import Footer from "./components/Footer/footer.component";
import Content from "./components/Content/content.component";
import { updateCount } from "./api/visitor-count";

export const UserContext = React.createContext();

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { count: undefined };
  }

  async componentDidMount() {
    const response = await updateCount();
    this.setState({ count: response.data.newCount });
  }

  render() {
    return (
      <UserContext.Provider value={this.state.count}>
        <Header />
        <Hero />
        <Content />
        <Footer />
      </UserContext.Provider>
    );
  }
}

export default App;
