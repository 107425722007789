import React from "react";
import styles from "./footer.module.scss";
import { IoLogoGithub, IoLogoLinkedin, IoMail } from "react-icons/io5";
import { UserContext } from "../../App";

export default function Footer() {
  const count = React.useContext(UserContext);

  return (
    <>
      <div className={styles.footerContainer}>
        <div className={styles.visitorCount}>
          <p> Welcome, visitor #{count}</p>
        </div>
        <div className={styles.copyWrite}>
          <p>© All rights reserved</p>
        </div>
        <div className={styles.contactIcons}>
          <a href="mailto:onimisiukanah@gmail.com">
            <IoMail className={styles.icon} />
          </a>
          <a
            href="https://www.linkedin.com/in/oukanah/"
            target="_blank"
            rel="noreferrer"
          >
            <IoLogoLinkedin className={styles.icon} />
          </a>
          <a href="https://github.com/onimisi" target="_blank" rel="noreferrer">
            <IoLogoGithub className={styles.icon} />
          </a>
        </div>
      </div>
    </>
  );
}
