import React from "react";
import styles from "./hero.module.scss";
import resume from "../../resume.pdf";
import { FiDownload } from "react-icons/fi";
import { UserContext } from "../../App";

export default function Hero() {
  const count = React.useContext(UserContext);

  return (
    <div className={styles.heroContainer}>
      <div className={styles.imageContainer}></div>
      <div className={styles.introContainer}>
        <h2>Software Engineer</h2>
        <h1>Onimisi Ukanah</h1>
        <p>
          Curious software engineer with a taste for adventure and a passion for
          innovation.
        </p>
        <a href={resume} download="onimisi-ukanah">
          <FiDownload />
          Download Resume
        </a>
        <p className={styles.visitorCount}> Welcome, visitor #{count}</p>
      </div>
    </div>
  );
}
